.add-section-btn {
	color: #04ada8;
	cursor: pointer;
	font-weight: 600;
	text-decoration: underline;
	margin-top: 20px;
	width: fit-content;
}

.topic-header-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border: 1px solid #e3dfdf;
	padding: 5px 10px;
}

.topic-header-icons-container {
	display: flex;
	flex-direction: row-reverse;
	column-gap: 5px;
}

.topic-tab {
	font-weight: 500;
	cursor: pointer;
	color: #1a3552;
}

.topic-tab-selected {
	font-weight: 700;
	border-bottom: 5px solid #1a3552;
	padding-bottom: 10px;
	cursor: text;
}

.btn-text-area-topic {
	font-size: 11px;
	position: absolute;
	bottom: 17px;
    right:0px;
	cursor: pointer;
    color:#fff;
    background-color: #37ADA7;
    border:1px solid #37ADA7;
    border-radius: 1px;
}

.topic-question-input-panel-desc {
	width: 100%;
	height: 54px;
	padding: 10px 2px 2px 10px;
	border-radius: 3px;
	border: 1px solid #e6e2e2;
	background-color: #ffffff;
	text-align: left;
	resize: none;
	font-family: 'Montserrat', sans-serif;
	position: relative;
}
