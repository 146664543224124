.sor-root {
	font-family: 'Montserrat', sans-serif;
	margin-left: 30px;
	margin-top: 25px;
}

.sor-header {
	display: flex;
	justify-content: space-between;
}

.sor-header-text {
	width: 70%;
	font-weight: 700;
	font-style: normal;
	font-size: 18px;
	color: #024371;
	margin: 0;
}

.sor-header-filter {
	display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 10px;
    margin-top: 0px;
    margin-right: 26px;
}

.sor-header-filter div {
	margin-right: 8px;
}

.sor-header-filter div:last-child {
	margin-right: 0;
}

.lbl-header-text {
	display: inline-block;
	max-width: 100%;
	margin-bottom: 5px;
	font-weight: 700;
	color:#222222
}

/* ============ dropdown-combo =============*/

.btn-group {
	position: relative;
	display: inline-block;
}

.btn {
	background-color: #37ADA7;
	border: 1px solid #37ADA7;
	padding: 10px 15px;
	cursor: pointer;
	font-size: 14px;
	color: #fff;
	border-radius: 7%;
}

.btn:hover {
	background-color: #37ADA7;
}

.btn.clicked {
	background-color: #37ADA7;
}

.btn .chevron-down {
	margin-left: 5px;
	transition: transform 0.2s ease;
}

.btn.open .chevron-down {
	transform: rotate(180deg);
}

/* Additional styles for the dropdown menu */
.dropdown-menu {
	display: none;
	position: absolute;
	background-color: #fff;
	border: 1px solid #ccc;
	min-width: 200px;
	z-index: 1;
	left: -126px;
}

/* Show the dropdown menu when the button is clicked */
.show {
	display: block;
}

.dropdown-menu li {
	padding: 8px 15px;
}

/* Remove li background color and set text color when hovering */
.dropdown-menu li:not(:first-child):hover {
	background-color: transparent;
}

/* Set text color for the li elements when hovering */
.dropdown-menu li a:hover {
	color: #337ab7;
	cursor: pointer;
}

/* Styles for the dropdown header */
.dropdown-header {
	font-weight: bold;
	padding: 8px 15px;
	background-color: #f0f0f0;
}

/*================================*/

/* ======= Funnel and reload icon==========*/
.icon-container-funnel,
.icon-container-reload {
	width: 45px;
	height: 37px;
	background-color: #37ADA7;
	border-radius: 7%;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.icon-container-funnel:hover {
	background-color: #37ADA7;
}

.icon-container-funnel.clicked {
	background-color: #37ADA7;
}

.icon-container-reload:hover {
	background-color: #37ADA7;
}

.icon-container-reload.clicked {
	background-color: #37ADA7;
}

/* Style for the icon */
.icon-container-funnel,
.icon-container-reload svg {
	color: #fff;
	font-size: 16px;
}

/* ========== Funnel ends =================*/

/* ============== Report container and its child components ==============*/

.sor-container {
	display: flex;
	flex-wrap: wrap;
	gap: 15px;
}

.report-display {
	width: 100%;
	border: 1px solid #cfcfcf;
	box-sizing: border-box;
	transition: width 0.4s ease;
	border-radius: 8px;
	background-color: #fff;
}

.report-display.with-filter {
	width: 70%;
}

.filter-option {
	width: 28%;
	border: 1px solid #CFCFCF;
	box-sizing: border-box;
	border-radius: 5px;
	max-height: 135vh;
	background-color: #fff;
}

.filter-content {
	opacity: 0;
	animation: fadeIn 0.4s forwards;
}

/* Animation for fade-in effect */
@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

.filter-option.show .filter-content {
	animation-delay: 0.2s;
}

/* ========================= End ======================= */

/* ======== reportpanel================*/

.employee-container {
	margin-bottom: 20px;
}

.employee-name {
	color: #004273;
	font-weight: 700;
	text-align: left;
	padding-left: 20px;
	font-size: 25px;
}

.employee-row {
	font-size: 16px;
}

.employee-job-title {
	color: #666;
	font-weight: 700;
}

.employee-description {
	font-size: 16px;
	text-align: left;
	padding-left: 20px;
}

.employee-appointed {
	font-size: 16px;
	text-align: left;
	padding-left: 20px;
}

.employee-table {
	margin-bottom: 0;
	text-align: left;
	width: 100%;
	max-width: 100%;
	border: 1px solid #ddd;
	border-left: none;
	border-right: none;
}

.employee-irn {
	width: 215px;
}

.employee-reg-status {
	width: 355px;
}

.employee-reg-status-date {
	width: 170px;
}

/* ===*/
.panel-heading {
	padding: 10px 15px;
	border-bottom: 1px solid transparent;
	background-color: #ecf4ff;
	border-radius: 5px 5px 0 0;
	-moz-border-radius: 5px 5px 0 0;
	-webkit-border-radius: 5px 5px 0 0;
	color: #0C6EE4;
}

.panel-title {
	margin-top: 3px;
	margin-bottom: 4px;
}

.filter-container-first {
	margin-left: 10px;
	margin-right: 10px;
	margin-top: 12px;
}

.filter-box {
	border: 1px solid #cfcfcf;
	border-radius: 8px;
	background-color: #f9f9f9;
	padding-left: 10px;
	cursor: pointer;
	height: 66px;
}

.filter-box p:first-child {
	color: #222;
	font-weight: 500;
}

.filter-box p:last-child {
	color: #222;

}

.primary-text {
	font-size: 13px;
}

.secondary-text {
	font-size: 13px;
}

.filter-date,
.filter-status,
.filter-sortby {
	margin-top: 11px;
}

.horizontal-line {
	border-color: #649fea;
	border: 0;
	height: 1px;
	width: 200px;
	margin: 20px auto;
	background: #dbdbdb;
}

/* checbox filter =========== */

.form-group {
	display: block;
	margin-bottom: 10px;
}

.input-group {
	display: flex;
	align-items: center;
	border: 1px solid #cfcfcf;
	border-radius: 2px;
	height: 38px;
	border-radius: 5px;
	border-left: none;
}

.checkbox-container {
	background-color: #f9f9f9;
	height: 38px;
	width: 36px;
	border: 1px solid #cfcfcf;
	margin: 0px;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
}

.input-group input[type="checkbox"] {
	margin-right: 5px;
	border: 1px solid #649fea;
	border-radius: 2px;
	width: 16px;
	height: 28px;
	margin-left: 9px;
}

.input-group span {
	font-style: italic;
	margin-left: 8px;
	font-size: 14px;
	color: #222;
	font-style: italic;
}

/*  =================  dropdown ============= */

.form-control-dropdown {
	width: 100%;
	padding: 8px 12px;
	font-size: 14px;
	color: black;
	border: 1px solid #d6d6d6;
	border-radius: 5px;
	background-color: #fff;
	cursor: pointer;
}

.form-control option {
	font-size: 14px;
	color: #0075ff;
}

.form-control::after {
	content: '\25BC';
	position: absolute;
	right: 10px;
	top: 50%;
	transform: translateY(-50%);
	font-size: 12px;
	pointer-events: none;
}

.report-panel-print {
	height: 157px;
	background-color: #fff;
}

.button-container {
	display: flex;
	justify-content: center;
}

.button-container button {
	padding: 8px 16px;
	font-size: 14px;
	border-radius: 2px;
	cursor: pointer;
	width: 142px;
}

.apply-button {
	background-color: #fff;
	color: #222;
	margin-right: 10px;
	border:1px solid #ddd
}

.generate-button {
	background-color: #37ADA7;
	color: #fff;
	border:1px solid #37ADA7
}

/* ======== Pop over ====== */
.popover {
	position: absolute;
	background-color: #f9f9f9;
	padding: 10px;
	border-radius: 5px;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
	z-index: 2;
	width: 295px;
	border: 1px solid #cfcfcf;
}

.pop-personal {
	height: 392px;
}

.pop-date {
	height: 193px;
}
.pop-status {
	height: 210px;
}

.pop-sortby {
	height: 214px;
}

.pop-roletypes {
	height: 385px;
}

/* .popover:after, .arrow_box:before {
	left: 100%;
	top: 50%;
	border: 1px solid #285B99;
	content: "";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}

.popover:after {
	border-color: rgba(255, 255, 255, 0);
	border-left-color: #f9f9f9;
	border-width: 30px;
	margin-top: -30px;
}
.popover:before {
	border-color: rgba(66, 132, 245, 0);
	border-left-color: #285B99;
	border-width: 31px;
	margin-top: -31px;
} */

.popover:after,
.arrow_box:before {
	left: 100%;
	top: 50%;
	border: 1px solid #285B99;
	content: "";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}

.popover:after {
	border-color: rgba(255, 255, 255, 0);
	border-left-color: #f9f9f9;
	border-width: 30px;
	margin-top: -30px;
}

.popover:before {
	border-color: rgba(66, 132, 245, 0);
	border-left-color: #285B99;
	border-width: 31px;
	margin-top: -31px;
}

/* Additional CSS for border */
.popover:after {
	border-style: solid;
}

.popover:before {
	border-style: solid;
}

/* Additional CSS for 1px solid black border */
.popover:before {
	border: 1px solid black;
}

.search-box {
	height: 28px;
	font-weight: 400;
	font-style: normal;
	font-size: 10px;
	padding-left: 16px;
	padding-right: 16px;
}

.personnel-container {
	width: 100%;
	margin-top: 18px;
}

/* ==================== */
.search-container {
	display: inline-block;
	position: relative;
	width: 100%;
}

.search-icon {
	position: absolute;
	top: 50%;
	left: 8px;
	transform: translateY(-50%);
	color: #836967;
	pointer-events: none;
}

.search-input {
	height: 28px;
	font-weight: 400;
	font-style: normal;
	font-size: 14px;
	padding-left: 28px;
	padding-right: 10px;
	border: 1px solid #ccc;
	border-radius: 5px;
	width: 86%;
}

.search-input:focus {
	border: 1px solid #285B99;
	outline: none;
}

.search-icon-border {
	position: absolute;
	top: 50%;
	left: 1px;
	transform: translateY(-50%);
	background-color: #cbcbcb;
	padding: 10px;
	pointer-events: none;
	height: 10px;
	width: 6px;
}

.select-smr-popover-selected {
	font-weight: 400;
	font-size: 12px;
	color: #222;
	text-align: right;
	display: block;
}

.personnel-employees {
	max-height: 270px;
	overflow-y: auto;
	margin-left: 24px;
}

.personnel-employee-done {
	color: #fff;
	background-color: #37ADA7;
	border:1px solid #37ADA7;
	height: 29px;
	border-radius: 4px;
	width: 79px;
	cursor: pointer;
	position: absolute;
	right: 31px;
}

.personnel-li {
	cursor: pointer;
}
.personnel-li:hover {
	background-color: #f9f9f9;
}

.personnel-check-icon {
	width: 13px;
	margin-right: 7px;
}

.personnel-container {
	width: 100%;
	margin-top: 18px;
}

.date-filter-custom-calendar {
	height: 34px;
	margin-left: 7px;
	margin-top: 5px;
	width: 239px;
}


/* hide calendar icon */


.date-filter-custom-calendar::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
}

.text-overflow-handle{
	overflow-y: auto;
	height: 20px;
	word-break: break-all;
}
