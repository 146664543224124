.btn-success {
    background-color: #18b8a5;
    border: 1px solid #18b8a5;
    color: white;
    border-radius: 5px;
    padding: 8px 20px;
    box-sizing: border-box;
    cursor: pointer;
}

.footer {
    display: flex;
    flex-direction: row-reverse;
    column-gap: 15px;
    margin-top: 20px;
    padding-bottom: 20px;
}

.mcc-alert {
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
}

.error {
    background-color: rgb(255, 235, 235);
    border: 2px solid rgb(248, 90, 116);
}

.disabled {
    filter: grayscale(1);
    cursor: not-allowed;
    pointer-events: none;
}