.mc-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 30px;
}

.mc-left-pane-container {
    width: 20%;
    height: 550px;
    /* border: 1px solid black; */
}

.mc-right-pane-container {
    width: 78%;
    height: 550px;
    /* border: 1px solid red; */
}

.mc-tabs-container {
    display: flex;
    flex-direction: column;
    row-gap: 3px;
}

.mc-tab {
    background-color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    cursor: pointer;
}

.mc-textbox {
    height: 40px;
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #282828;
    font-family: 'Montserrat', sans-serif;
}

.mc-general-container {
    width: 100%;
    padding : 5px 30px 40px 30px;
    box-sizing: border-box;
    background-color: #fff;
}

.mc-table-container {
    position: relative;
}

.mc-general-empty-container {
    position: relative;
    width: 100%;
    height: 600px;
    padding : 5px 30px 40px 30px;
    box-sizing: border-box;
    background-color: #fff;
}

.mc-general-label {
    margin-top : 30px;
    margin-bottom: 10px;
    line-height: 21px;
}

.mc-general-label-header{
    font-weight: 500;
}

.mc-general-input-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.mc-general-input {
    font-size : 14px;
    padding: 5px;
    border-radius: 3px;
    margin-right: 5px; 
    box-shadow: 0px 0px 2px rgba(120, 120, 120, .5);
    border: 1px solid #aaa;
    width: 40px;
    outline: none;
    font-family: 'Montserrat', sans-serif;
}

.mc-general-footer {
    margin-top : 30px;
    display: flex;
    flex-direction: row;
    column-gap : 10px;
    justify-content: flex-end;
    align-items: center;
}

.mc-general-save-button {
    padding: 10px 20px;
    background-color: #37ADA7;
    font-size: 14px;
    color : #fff;
    border: none;
    cursor: pointer;
}

.mc-general-cancel-button {
    padding: 10px 20px;
    background-color: #fff;
    font-size: 14px;
    color : #222;
    border: 1px solid #ddd;
    cursor: pointer;
}

.mc-roles-top-container {
    display: flex;
    flex-direction: row-reverse;
    padding-top: 20px;
    padding-bottom: 10px;
}

.mc-roles-add-icon {
    color: rgb(57, 179, 173);
    font-size: 18px;
    cursor: pointer;
}

.mc-roles-table-cell-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding : 0px 5px 0px 5px;

}

.mc-roles-table-action-container {
    display: flex;
    width : 40%;
    flex-direction: row;
    justify-content: flex-end;
    column-gap: 10px;
    text-align: center;
}

.interactive-overlay{
    position : absolute;
    top : 0;
    left : 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255,0.8);
    box-sizing: border-box;
}

.mc-btn-sm {
    background-color: #39b3ad;
    color: #fff;
    padding: 2px 6px;
    font-size: 9px;
    font-weight: 500;
    border-radius: 2px;
    cursor: pointer;
}

.mc-btn-sm:hover {
    box-shadow: 2px 2px 3px 0px #ccc;
}

.mc-table-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px 10px 5px;
    border-top: 1px solid #e0e0e0;
    border-left: 1px solid #e0e0e0;
    border-right: 1px solid #e0e0e0;
}

.mc-general-icon {
    color: #39b3ad;
    cursor: pointer;
    font-size: 17px;
}

.mc-dropdown {
    display: block;
    box-sizing: border-box;
    height: 36px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #fff;
    border-radius: 3px;
    box-shadow: #c4c4c4 0px 0px 4px;
    font-family: Montserrat, sans-serif;
    color: #222222;
    padding: 7px;
}

.mc-dropdown-sm {
    display: block;
    box-sizing: border-box;
    width: 5%;
    background-color: #fff;
    border: 1px solid #fff;
    border-radius: 3px;
    box-shadow: #c4c4c4 0px 0px 4px;
    font-family: Montserrat, sans-serif;
    color: #222222;
    padding: 2px;
}

.mc-cert-setup-container {
    width: 100%;
    padding: 0;
    max-height: 69vh;
    overflow-y: auto;
    margin : 10px 0
}

.mc-cert-setup-label-container {
    width: 100%;
    display: block;
    padding: 7px 0;
}

.mc-cert-setup-label-container-flex {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 7px 0;
    padding-right : 20%;
    box-sizing: border-box;
}

.mc-cert-setup-label {
    display: inline-block;
}

.mc-cert-setup-label-main {
    font-weight: 600;
}

.mc-cert-setup-field-container{
    width: 100%;
    padding : 0 15% 0 2%;
    margin: 5px 0 10px 0;
    box-sizing: border-box;
}

.mc-cert-setup-icon{
    position : relative;
    font-size: 1rem;
    cursor : pointer;
    margin-left : 5px;
}

.mc-cert-setup-logo-selection-container{
    position : relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 2rem;
    align-items: center;
    margin: 5px 0 10px 0;
    padding : 0 15% 0 2%;
}

.mc-cert-setup-logo-selection-option-container{
    position : relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap : 10px;
}

.mc-cert-setup-logo-selection-option{
    position : relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap : 15px;
}

.mc-cert-setup-radio{
    font-size: 1rem;
    color: #426de1;
}

.mc-cert-setup-upload{
    font-size: 1rem;
    background-color: #426de1;
    border: 1px solid #426de1;
    color : #fff;
    padding : 4px 9px;
    border-radius: 3px;
}

.mc-cert-setup-logo-container{
    border : 1px solid #ddd;
    height: 8vh;
    width: 20vh;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    padding: 5px;
}