.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.MicroserviceHeader {
  background: #115D9C;
  text-align: left;
  color: white;
  font-size: 18px;
  font-weight: 600;
  width:100%;
  height: 34px;
  padding-left: 10px;
  padding-top: 7px;
  padding-bottom: 7px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.csl-config-container {
    width: 335px;
    height: calc(100vh - 65px);
    background-color: #f7f7f7;
    position: absolute;
    top: 34px;
    right: 16px;
    box-shadow: -10px 0 12px -2px #afafaf;
}

.csl-config-header-container {
  display: flex;
  box-sizing: border-box;
  width: 100%;
}

.csl-config-header-text {
  margin-top: 20px;
  margin-left: 15px;
  font-weight: 700;
  font-size: 14px;
  color: #515151;
}

.csl-config-header-close {
  display: inline-block;
  right: 15px;
  position: absolute;
  margin-top: 20px;
  cursor: pointer;
  color: #515151;
}

/* .react-date-picker__wrapper {
  border: none ! important;
  color: #515151 ! important;
}

.react-date-picker__inputGroup {
  color: #b7b7b7 ! important;
}
.react-date-picker__inputGroup__input {
  color: #b7b7b7 ! important;
} */
.csl-config-options-container {
  margin-top: 10px;
  margin-left: 15px;
  margin-bottom: 1px;
  font-weight: 500;
  color: #969696;
  font-size: 13px;
}

.csl-config-date-style {
  border: 0px;
  height: 30px;
  width: 175px;
  letter-spacing: 2px;
}

.csl-date-cover {
  margin-top: 8px;
  width: 95%;
  border-radius: 5px;
  background-color: #ffffff;
  height: 33px;
  padding-top: 5px;
  padding-left: 10px;
}

/*.container {
  width: 600px;
  padding: 50px 0;
  margin: 0 auto;
}*/

.csl-config-container  .checkbox {
    margin: 40px 0 ! important;
  }


.csl-config-container .cslradio {
  margin: 10px 0;
}


.csl-config-container input[type="radio"] + label {
  display: inline-block;
  cursor: pointer;
  position: relative;
  padding-left: 30px;
  margin-right: 15px;
  font-size: 13px;
}
.csl-config-container input[type="radio"] + label:before {
  content: "";
  display: block;
  width: 16px;
  height: 16px;
  margin-right: 8px;
  position: absolute;
  top: 0px;
  left: 0;
  border: 1px solid #aaa;
  background-color: #fff;
  border-radius: 3px;
}
.csl-config-container input[type="radio"] {
  display: none !important;
  *display: inline;
}
.csl-config-container input[type="radio"]:checked + label:after {
  content: "\2713";
  display: block;
  position: absolute;
  top: -1px;
  left: 3px;
  width: 12px;
  height: 12px;
  border-radius: 10%;
  /*background: #b7b7b7;*/
}




.csl-config-container input[type="checkbox"] + label {
  display: inline-block;
  cursor: pointer;
  position: relative;
  padding-left: 30px;
  margin-right: 15px;
  font-size: 13px;
}
.csl-config-container input[type="checkbox"] + label:before {
  content: "";
  display: block;
  width: 16px;
  height: 16px;
  margin-right: 8px;
  position: absolute;
  top: 0px;
  left: 0;
  border: 1px solid #aaa;
  background-color: #fff;
  border-radius: 3px;
}
.csl-config-container input[type="checkbox"] {
  display: none !important;
  *display: inline;
}
.csl-config-container input[type="checkbox"]:checked + label:after {
  content: "\2713";
  display: block;
  position: absolute;
  top: -1px;
  left: 3px;
  width: 12px;
  height: 12px;
  border-radius: 10%;
  /*background: #b7b7b7;*/
}
