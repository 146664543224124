.head-container{
    width : 100%;
    padding: 5px;
    display: flex;
    flex-direction: row;
    background-color: white;
    justify-content: space-between;
    box-sizing: border-box;
}

.head-container-left-pane{
    width: 60%;
    display: flex;
    justify-content: flex-start;
    column-gap: 7px;
    align-items: center;
}
.head-container-right-pane {
    width: 30%;
    display: flex;
    justify-content: flex-end;
    column-gap: 7px;
    align-items: center;
    box-sizing: border-box;
    margin-right: 5px;
}

.icon-container{
    background-color: #ede4e4;
    border-radius: 2px;
    color : #282828;
    font-size: 15px;
    padding: 3px;
    width: 25px;
    text-align: center;
    cursor: pointer;
}

.main-icon-container{
    background-color: #fff;
    border-radius: 2px;
    color : #1b1c1e;
    font-size: 15px;
    padding: 3px;
    width: 25px;
    text-align: center;
    cursor: pointer;
}

.head-container-app-name {
    font-size : 15px;
    color : #101010;
    margin-left: 5px;
    
}

.head-container-page-name {
    font-size : 15px;
    color : #37ADA7;
    font-weight: 600;
}