.gcRoles-model-section .ant-modal-content {
    border-radius: 0px !important;
    padding: 0px !important;
}

.gcRoles-model-section .ant-table-thead th {
    color: #0c6ee4 !important;
    font-weight: 600 !important;
}

.gc-close-btn {
    background-color: #18b8a5;
    border: 1px solid #18b8a5;
    color: #fff;
    border-radius: 5px;
    width: 80px;
    padding: 5px 10px;
    box-sizing: border-box;
    cursor: pointer;
}

.gcRoles-model-section .ant-modal-body {
    box-sizing: border-box;
    background-color: #f6f9fd;
}

.ant-pagination-item-active a {
    margin-top: -5px !important;
}
.ant-pagination-item a {
    margin-top: -5px !important;
}
.gcRoles-model-section .ant-btn-primary:hover {
    background-color: #18b8a5 !important;
    border: 1px solid #18b8a5 !important;
    color: #fff !important;
}
.gcRoles-model-section .ant-modal-footer {
    padding: 15px 14px 18px 15px !important
}
.gcRoles-model-section .ant-table-wrapper {
    max-width: 94% !important;
    margin-left: 20px !important;
    border: 1px solid #e6e6e6 !important
}

.ant-modal-mask {
    background-color: transparent !important
}

.gcRoles-model-section .ant-modal-header {
    margin-bottom: -1px !important
}

.gcRoles-model-section .ant-modal-close-x {
    cursor: pointer;
    font-size: 25px;
    font-weight: 200;
}

.gcRoles-model-section .ant-table-tbody {
    color: #222;
    font-family: Montserrat,sans-serif;
    font-size: 12px;
    font-weight: 500;
}