.modal-wrapper {
	position: relative;
	z-index: 9999;
}

.modal-popup {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
	position: relative;
	background-color: #fff;
	border: 1px solid #ccc;
	width: 391px;
	padding-right: 20px;
	max-width: 100%;
	max-height: 100%;
	overflow-y: auto;
	border-radius: 5px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.modal-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-left: 7px solid #37ada7;
	background: white;
	box-sizing: border-box;
	height: 52px;
}

.modal-header h2 {
	margin: 0;
	margin-left: 16px;
}

.modal-header button {
	background: none;
	border: none;
	cursor: pointer;
	font-size: 20px;
	font-weight: bold;
	padding: 0;
}

.modal-body {
	background-color: #f3f7fb;
	margin-left: 9px;
	margin-bottom: 12px;
}

.modal-footer {
	display: flex;
	justify-content: flex-end;
	margin-bottom: 14px;
}

.topic-attcahment-upload-button {
	background-color: #37ada7;
	color: white;
	border: none;
	border-radius: 4px;
	cursor: pointer;
	margin-top: 0px !important;
}

.save-button {
	color: black;
	border: none;
	font-size: 16px;
	cursor: pointer;
	border-radius: 4px;
	margin-left: 10px;
	width: 73px;
	border: 1px solid;
}

table.emp-file {
	/* border-collapse: collapse; */
	width: 70%;
	border-spacing: 10px;
}

tr:not(:first-child) {
	margin-top: 10px;
	padding: 10px 0;
}

td.emp-file-td {
	padding: 8px;
}

td.ext {
	width: 5%;
	text-align: center;
	padding-right: 10px;
}

td.attachment-action {
	width: 15%;
	text-align: right;
}

.modal-body-confirmation {
	margin-left: 9px;
	margin-bottom: 12px;
}

.modal-header2 {
	padding: 10px;
	padding-left: 20px;
	border-bottom: 1px solid black;
	background: white;
	box-sizing: border-box;
	height: 52px;
	border-top-right-radius: 15px;
	border-top-left-radius: 15px;
	text-align: center;
	background-color: #edf1f2;
}

.modal-body2 {
	padding: 10px;
	padding-left: 20px;
	font-size: 16px;
	background-color: #edf1f2;
}

.modal-footer2 {
	border-bottom-left-radius: 15px;
	border-bottom-right-radius: 15px;
	text-align: right;
	padding: 19px;
	background-color: #edf1f2;
	display: flex;
	justify-content: flex-end;
	margin-bottom: 14px;
}

/* ========assessment question attachment upload =========== */

.attachment-files-section {
	margin-left: 61px;
	margin-bottom: 10px;
}

.attachment-question-button {
	color: #fff;
	background-color: #37ada7;
	border: navajowhite;
	height: 27px;
	width: 96%;
	border-radius: 3px;
	cursor: pointer;
}

.attachment-file-tiles {
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
	margin-bottom: 10px;
}

.attachment-file-tile {
	position: relative;
	width: 100px;
	height: 100px; /* Adjust the height as needed */
	background-color: #f2f2f2;
	padding: 10px;
	border-radius: 5px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	overflow: hidden;
	cursor: pointer;
}

.attachment-file-name {
	font-size: 14px;
	max-width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.attachment-context-menu {
	position: absolute;
	top: 5px;
	right: 5px;
	display: flex;
	flex-direction: column;
	background-color: #ffffff;
	border: 1px solid #ccc;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	z-index: 1;
}

.attachment-context-menu button {
	padding: 5px 10px;
	border: none;
	background-color: transparent;
	cursor: pointer;
}

.file-context-elipse {
	position: absolute;
	top: 6px;
	right: 5px;
}

.show-alert-asterisk {
	font-size: 17px;
	margin-right: 4px;
	color: red;
}
