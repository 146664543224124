/*=========== code for date alignment =============== */
.employee-edit-date-container {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.employee-edit-date-picker {
	display: flex;
	flex-direction: column;
	margin-bottom: 16px;
}

.employee-edit-date-picker label {
	font-size: 14px;
	font-weight: 500;
	margin-bottom: 4px;
}

.employee-edit-date-picker input[type="date"] {
	height: 12px;
	width: 239px;
	padding: 8px;
	border: 1px solid #cccccc;
	border-radius: 4px;
	font-size: 14px;
	line-height: 1.5;
	color: #333333;
	background-color: #ffffff;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	font-family: 'Montserrat', sans-serif;
}

.employee-edit-date-picker input[type="date"]:focus {
	outline: 0;
	border-color: #2196f3;
	box-shadow: 0 0 0 0.2rem rgba(33, 150, 243, 0.25);
}

.custom-date-input {
	appearance: none;
	-webkit-appearance: none;
	margin: 0;
	padding: 0;
	border: none;
	outline: none;
	background: none;
	color: #333;
	font-family: 'Montserrat', sans-serif;
}

/* ====================== MODAL =============== */
.employee-edit-modal {
	position: fixed;
	z-index: 1;
	left: 79px;
	top: 250px;
	overflow: auto;
	background-color: rgba(0, 0, 0, 0.4);
	font-family: 'Montserrat', sans-serif;
	width: 450px;
	box-shadow: 8px 8px 8px 8px rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	background-color: #f7f7f7;
	color: #2d2d2d;
	border-radius: 10px;
	border-style: solid;
	border-color: #f7f7f7;

}


/* Modal Content/Box */
.employee-edit-modal-content {
	background-color: #f7f7f8;
	border-radius: 4px;
}

/* Modal Header */
.employee-edit-modal-header {
	width: calc(100% - 15px);
	background-color: #f7f7f7;
	color: #646a7e;
	border-bottom-style: solid;
	border-bottom-width: 2px;
	height: 35px;
	padding-left: 15px;
	padding-top: 15px;
	font-size: 20px;
	font-weight: 600;
}

/* Modal Title */
.employee-edit-modal-title {
	font-size: 18px;
	font-weight: bold;
}

/* Modal Close Button Hover */
.employee-edit-modal-close:hover,
.employee-edit-modal-close:focus {
	color: #000;
	text-decoration: none;
	cursor: pointer;
}

/* Modal Body */
.employee-edit-modal-body {
	padding: 10px 15px;
}

/* Modal Footer */
.employee-edit-modal-footer {
	background-color: #edf1f2;
	padding: 10px 15px;
	color: #2d2d2d;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	display: flex;
	justify-content: flex-end;
}

/* Modal Button */
.employee-edit-modal-button {
	display: inline-block;
	box-sizing: border-box;
	background-color: #04aca7;
	color: #ffffff;
	width: 100px;
	height: 35px;
	border-radius: 4px;
	border: 1px solid #37ada7;
	margin-left: 5px;
	font-size: 11px;
	cursor: pointer;
	font-size: 18px;
}
