.csf-container{
    width : 100%;
    border: 1px solid #a6cbf0;
}

.csf-header{
    width : 100%;
    height: 3vh;
    padding : 5px;
    box-sizing: border-box;
    background-color: #F0F6FC;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.csf-body{
    position : relative;
    width : 100%;
    height: 9vh;
    padding : 7px;
    box-sizing: border-box;
}

.csf-v-separator{
    width : 2px;
    height : 90%;
    background-color: #606f7e;
    margin-left: 10px;
}

.csf-select{
    margin-left : 10px;
    border : 1px solid #ddd;
    outline: none;
    font-family: 'Montserrat';
}

.csf-button{
    width : 2.2vh;
    height : 2.2vh;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left : 10px;
    border : 1px solid #F0F6FC;
    cursor: pointer;
}

.csf-button-bold{
    font-weight: 700;
}

.csf-button-italic{
    font-style: italic;
}

.csf-button-active{
    background-color: #c7cacc;
    border : 1px solid #aaa;
}

.csf-button-inactive{
    color: #acb3b8;
    cursor : auto;
}

.csf-color-input{
    position : absolute;
    top : 100%;
    left : 80%;
    z-index: 100;
}

.csf-color-label{
    margin-left : 10px;
    position : relative;
    display : flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap : 8px;
}

.csf-body-input{
    width : 92%;
    margin : 0;
    padding : 0;
    height : 100%;
    border : none;
    outline : none;
    padding-left : 10px;
    display : flex;
    justify-content: flex-start;
    align-items: center;
}

.csf-check-icon{
    position : relative;
    font-size: 1rem;
    cursor : pointer;
    margin-right : 5px;
}

.csf-date-format-container{
    position : absolute;
    top : 50%;
    transform: translateY(-50%);
    left : 30%;
}