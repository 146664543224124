.tab-inactive{
    border-top-left-radius : 6px;
	border-top-right-radius : 6px;
    border : 1px solid #CDCECF;
    border-bottom-color:#E7EAED;
    margin-right: 6px;
    padding: 5px  10px;
    background-color: #E7EAED;
    color:#1B3451;
    display:inline-block;
    font-weight: 700;
	/* min-width:10%; */
	cursor:pointer;
    white-space: nowrap;
}

.tab-active {
    border-top-left-radius : 6px;
	border-top-right-radius : 6px;
    border : 1px solid #CDCECF;
    border-bottom-color:#FFFFFF;
    margin-right: 6px;
    padding: 5px 10px;
    background-color: #FFFFFF;
    color:#1B3451;
    display:inline-block;
    font-weight: 700;
	/* min-width:10%; */
	cursor:pointer;
    white-space: nowrap;
}

.tab-container {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 99%;
    align-items: center;
    padding-left: 10px;
    margin-right: 10px;
    flex-wrap: nowrap;
    /* margin-top : -2px; */
    overflow-x: scroll;
    scrollbar-width: none;  
    -ms-overflow-style: none;
    scroll-behavior: smooth;
}

.tab-container::-webkit-scrollbar {
    display: none;
}

.right-arrow {
    width: 3%;
    display: inline-block;
    background: #E7EAED;  
}

.left-arrow {    
    width: 3%;
    display: inline-block;
    background: #E7EAED;    
    margin-left: 7px;
}

.tab-active-rev {
    border-bottom-left-radius : 6px;
	border-bottom-right-radius : 6px;
    border : 1px solid #CDCECF;
    border-top-color:#FFFFFF;
    margin-right: 6px;
    padding: 5px 10px;
    background-color: #FFFFFF;
    color:#1B3451;
    display:inline-block;
    font-weight: 700;
	min-width:10%;
	cursor:pointer;
}

.tab-inactive-rev {
    border-bottom-left-radius : 6px;
	border-bottom-right-radius : 6px;
    border : 1px solid #CDCECF;
    border-top-color:#E7EAED;
    margin-right: 6px;
    padding: 5px  10px;
    background-color: #E7EAED;
    color:#1B3451;
    display:inline-block;
    font-weight: 700;
	min-width:10%;
	cursor:pointer;
}