.Table__itemCount {
  /* margin-top: 10px; */
  font-size: 14px;
}

.Table__pagination {
  display: flex;
  justify-content: flex-end;
  /*padding: 20px 10px;*/
}

.Table__pageButton {
  font-size: 15px;
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.Table__pageButton:disabled {
  cursor: not-allowed;
  color: gray;
}

.Table__pageButton--active {
  color: #45b3e3;
  font-weight: bold;
}

.ReactTable .rt-thead.-header {
    box-shadow: none;
    background: #f6f9fd;
    /* color: #0c6ee4; */
    padding: 7px 0px 7px 0px;
}

.ReactTable .rt-resizable-header-content {
  color: #0c6ee4;
  font-weight: 600;
}

.ReactTable .rt-td {
  color:#222222;
  font-weight: 500;
  font-size: 12px;
}

.ReactTable .rt-tbody .rt-td {
  border: none;
}

.ReactTable .rt-thead.-filters .rt-th {
  border: none;
}

.ReactTable .rt-tbody .rt-tr-group {
  border: none;
  padding-top: 5px;
  padding-bottom: 5px;
}

.ReactTable .rt-thead .rt-tr {
  text-align: justify;
}

#mc-role .ReactTable .rt-tbody .rt-tr-group {
  height: 37px;
}