.index-container {
    box-sizing: border-box;
    padding: 8px 5px 8px 5px;
}

.top-div {
    background-color: #fff;
    padding: 15px 0px 15px 20px;
    font-weight: 700;
    font-size: 14px;
    margin-top: 25px;
    border: 1px solid #e1dddd;
}

.index-ellipsis-container{
    position: relative;
    float : right;
    margin-right: 15px;
}

.index-table-header-context-menu{
    position : absolute;
    background-color: #f3f0f0;
    padding: 5px 5px;
    display: inline-block;
    right : 10px;
    top : 10px;
    width: 100px;
    box-shadow: 0px 0px 4px rgba(0,0,0,0.4);
    z-index: 10000;
    box-sizing: border-box;
}

.info-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #E7F0F7;
    padding: 20px;
    border: 1px solid #d1d0d0;
    border-radius: 3px;
}

.info-sub-container {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    /* border: 1px solid #000; */
    width: 98%;
    box-sizing: border-box;
}

.info-item-container {
    display: flex;
    flex-direction: row;
    /* column-gap: 25px; */
}
.multiple {
    font-weight: 600;
    color: #24B3AD;
    cursor: pointer;
}

.single {
    font-weight: 600;
    color: #222222;
    cursor: text;
}