.searchable-combo-box {
  position: relative;
  display: inline-block;
  width:96%;
  font-family: 'Montserrat', sans-serif;
}

.searchable-combo-box-input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-family: 'Montserrat', sans-serif;
}

.searchable-combo-box-options {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 10;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 4px 4px;
  font-family: 'Montserrat', sans-serif;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); */
}

.searchable-combo-box-option {
  display: block;
  padding: 10px;
  font-size: 16px;
  color: #333;
  cursor: pointer;
  font-family: 'Montserrat', sans-serif;
}

.searchable-combo-box-option:hover,
.searchable-combo-box-option:focus {
  background-color: #f2f2f2;
}

.searchable-combo-box-option.not-found {
  font-style: italic;
  color: #888;
  font-family: 'Montserrat', sans-serif;
}
