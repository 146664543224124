li,
a,
p {
    font-size: 16px;
}

ul {
    list-style: none;
    -webkit-padding-start: 0px
}

li {
    padding: 7px;
}

a {
    text-decoration: none;
    color: #1a1a1a;
}

a:hover {
    text-decoration: none;
}

li:hover {
    background: #efefef
}

/* .menu-wrapper {
    position: absolute;
    margin-right: 150px;
} */

.menu-wrapper a {
    cursor: pointer;
}

.menu {
    position: absolute;
    margin-top : -20px;
    right: 27px;
    width: 140px;
    background: white;
    padding: 2px;
    box-shadow: 1px 2px 3px 0 rgba(21, 21, 21, 0.6);
    transition: all 0.3s cubic-bezier(.35, 0, .25, 1);
    transform-origin: top right;
    font-size: 0.8em;
    z-index: 10000 !important;
}


.menu-icon {
    width: 18px;
}

.menu-open {
    visibility: visible;
    transform: scale(1);
    opacity: 1;
    white-space: nowrap;
    display: inline-block;
    width:150px;
}

.menu-close {
    visibility: hidden;
    transform: scale(0);
    opacity: 0;
}
