.multi-select-dropdown-wrapper{
    position: relative;
    /* padding: 10px; */
    margin-top: 5px;
    margin-bottom: 5px;
    box-sizing: border-box;
    /* border: 1px solid black; */
    height: 30px;
}
.multi-select-dropdown-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-height: 150px;
    width: 100%;
    background-color: #fff;
    padding: 0px 5px;
    box-sizing: border-box;
    border: 1px solid #eee;
    min-height: 40px;
}
.multi-select-dropdown-pill-container{
    display: flex;
    flex-direction: row;
    column-gap: 5px;
    row-gap: 8px;
    flex-wrap: wrap;
    max-height: 43px;
    scroll-behavior: auto;
    color :#fff;
    box-sizing: border-box;
    width : 90%;
    align-items: center;
    overflow-y: auto;
    padding: 4px 0px;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.multi-select-dropdown-pill-container::-webkit-scrollbar {
    display: none;
}

.multi-select-dropdown-pill{
    padding: 5px;
    border-radius: 3px;
    min-width: 50px;
    height: 20px;
    background-color: #243e57;
    display: flex;
    flex-direction: row;
    column-gap: 3px;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.multi-select-dropdown-pill-title{
    font-size: 14px;
    cursor: pointer;
}

.multi-select-dropdown-pill-cross{
    font-size: 14px;
}

.multi-select-dropdown-list{
    max-height: 500px;
    background-color: #fff;
    box-shadow: 0px 0px 3px #959595;
    margin: 8px 5px 0px 5px;
    box-sizing: border-box;
    position: relative;
    z-index: 1;
}

.multi-select-dropdown-list-item{
    padding: 8px 5px 8px 5px;
    border-bottom: 1px solid #ddd;
    display: block;
    cursor: pointer;
}

.multi-select-dropdown-list-item:hover {
    background-color: #eee;
    font-weight: 600;
    cursor: pointer;
}

.dropdown-icon{
    font-size : 15px;
    color : #555;
    padding : 10px 5px;
    font-size : 15px;
    font-weight: bold;
}

.search-input{
    outline: none;
    border : none;
    font-size: 14px;
}
