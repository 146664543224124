.add-role-modal-container {
	position: relative;
	left: 50%;
	transform: translateX(-50%);
	top: 10%;
	width: 43%;
	background-color: #fff;
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4);
}

.add-role-modal-header-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	border-left: 8px solid #11aba6;
	padding: 15px 20px;
}

.add-role-modal-header-text-container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.add-role-modal-header-title {
	font-size: 20px;
	font-weight: 500;
	margin-bottom: 10px;
}

.add-role-modal-header-subtitle {
	font-size: 13px;
}

.add-role-modal-body-container {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	row-gap: 25px;
	padding: 30px 20px 30px 30px;
	box-sizing: border-box;
	background-color: #f6f9fd;
}

.add-role-modal-body-container-large {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	row-gap: 25px;
	padding: 30px 20px 30px 30px;
	box-sizing: border-box;
	background-color: #f6f9fd;
	scroll-behavior: auto;
	height: calc(100vh - 382px);
	overflow-y: hidden;
	scrollbar-gutter: stable;
}

.add-role-modal-body-container-large:hover {
	overflow-y: scroll;
	scrollbar-gutter: stable;
}

.add-role-modal-label {
	font-size: 16px;
	font-weight: 500;
	margin-bottom: 10px;
}

.add-role-modal-label-small {
	font-size: 12px;
	font-style: italic;
	margin-bottom: 10px;
}

.add-role-modal-input {
	font-size: 14px;
	padding: 5px;
	border-radius: 3px;
	box-shadow: 0px 0px 2px rgba(120, 120, 120, .5);
	border: 1px solid #aaa;
	width: 100%;
	outline: none;
	font-family: 'Montserrat', sans-serif;
	box-sizing: border-box;
	background-color: #fff;
}

.add-role-modal-textarea {
	width: 100%;
	border-radius: 3px;
	box-shadow: 0px 0px 2px rgba(120, 120, 120, .5);
	border: 1px solid #aaa;
	padding: 5px;
	font-family: 'Montserrat', sans-serif;
	box-sizing: border-box;
	outline: none;
	resize: none;
}

.add-role-modal-label2 {
	font-size: 16px;
	font-weight: 500;
}

.context-menu {
	position: absolute;
	background-color: #f3f0f0;
	padding: 5px 5px;
	display: inline-block;
	min-width: 75px;
	right: 23px;
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4);
}

.context-menu-item {
	font-size: 14px;
	padding: 5px;
	font-weight: 500;
	cursor: pointer;
}
.context-menu-item:hover {
	background-color: #e1dfdf;
	font-weight: 600;
}

.add-role-modal-footer-container {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	column-gap: 15px;
	padding: 20px 30px;
	box-sizing: border-box;
	background-color: #fff;
	box-shadow: inset 0 4px 5px -5px #a8a7a5;
}

.add-role-modal-save-button {
	background-color: #18b8a5;
	border: 1px solid #18b8a5;
	color: white;
	border-radius: 5px;
	width: 80px;
	padding: 5px 10px;
	box-sizing: border-box;
	cursor: pointer;
}

.add-role-modal-cancel-button {
	background-color: #fff;
	border: 1px solid #222;
	color: #222;
	border-radius: 5px;
	width: 80px;
	padding: 5px 10px;
	box-sizing: border-box;
	cursor: pointer;
}

.modal-section {
	width: 100%;
	display: flex;
	flex-direction: column;
	row-gap: 25px;
	box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.4);
	box-sizing: border-box;
	padding: 15px 10px 15px 10px;
	background-color: #fff;
}

/* uploadattachments */
.topic-attachment-div {
	height: auto;
	width: calc(100% - 10px);
	color: #282828;
	font-family: 'Montserrat', sans-serif;
}

.topic-attcahment-upload-button {
	background-color: #18b8a5;
	border: 1px solid #18b8a5;
	color: white;
	border-radius: 5px;
	width: 80px;
	padding: 5px 10px;
	box-sizing: border-box;
	cursor: pointer;
	margin-top: 9px;
}

/* Assessment configs tab */
.assessment-config-container {
	width: 90%;
	height: 500px;
	padding: 2px 2px 2px 2px;
	background-color: #ffffff;
	box-sizing: border-box;
	margin-top: 15px;
}
.assessment-box-container {
	background-color: #f6f9fd;
	font-family: 'Montserrat', sans-serif;
}

.assessment-block-container {
	height: 59px;
    background-color: #f6f9fd;
    display: flex;
    margin-top: 2px;
}


.assessment-config-text-div {
	height: 40px;
	text-align: left;
	line-height: normal;
	margin-right: 86px;
	margin-left: 24px;
	width: 25%;
	white-space: nowrap;
}

.assessment-config-text {
	background-color: rgba(255, 255, 255, 0);
	box-sizing: border-box;
	font-family: "Montserrat",sans-serif;
	font-weight: 500;
	color: #537386;
	font-size: 13px;
	margin-top: 7px;
}

.assessment-config-body-container {
    height: 100px;
    background-color: #ffffff;
    margin-left: 5px;
    margin-top: 27px;
}

.assessment-config-default-acessor {
	width: 117px;
	height: 30px;
	background-color: rgba(255, 255, 255, 0);
	box-sizing: border-box;
	font-family: "Montserrat",sans-serif;
	font-weight: bold;
	color: #1a3552;
	text-align: left;
	line-height: normal;
	margin-top: 10px;
}

.assessment-config-big-text {
	height: 30px;
	font-size: 13px;
	background-color: rgba(255, 255, 255, 0);
	box-sizing: border-box;
	font-family: "Montserrat", sans-serif;
	color: #537386;
	text-align: left;
	line-height: normal;
	padding-bottom: 30px;
}

.assessment-config-dropdown {
	box-sizing: border-box;
	height: 40px;
	width: 100%;
	background-color: #fff;
	border: 1px solid #ffffff;
	border-radius: 3px;
	box-shadow: 0 0 4px #c4c4c4;
	font-family: 'Montserrat', sans-serif;
	color: #5f6368;
}

.assessment-config-dropdown-label {
	color: #212121;
	font-weight: 600;
	margin-top: 15px;
	margin-bottom: 5px;
	display: inline-block;
}

.mc-em-modal-body {
	padding: 30px 20px 30px 30px;
	box-sizing: border-box;
	background-color: #f6f9fd;
	height: calc(100vh - 41vh);
	overflow-y: hidden;
	scrollbar-gutter: stable;
	scroll-behavior: auto;
}

.mc-em-modal-body:hover {
	overflow-y: scroll;
	scrollbar-gutter: stable;
}

.mc-em-hr {
    width: 100%;
    height: 2px;
    background-color: rgb(235, 235, 235);
    margin-top: 20px;
}

.mc-em-vr-pill {
    padding: 5px 12px;
    background-color: rgb(255, 255, 255);
    color: rgb(34, 34, 34);
    font-weight: 600;
    border: 1px solid rgb(34, 34, 34);
    display: inline-block;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 10px;
    border-radius: 12px;
    cursor: pointer;
}

.mc-em-vr-pill:hover {
	background-color: rgb(227 224 224);
}

.mc-em-modal-footer-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 20px 30px;
	box-sizing: border-box;
	background-color: #fff;
	box-shadow: inset 0 4px 5px -5px #a8a7a5;
}

.mc-em-btn {
    padding: 8px 15px;
    border-radius: 5px;
	cursor: pointer;
}

.mc-em-success {
	background-color: #18b8a5;
	border: 1px solid #18b8a5;
	color: #fff;
}

.mc-em-normal {
	background-color: #fff;
	border: 1px solid #222222;
	color: #222222;
}

.review-role-modal-body-sbcontainer{
	height: 6vh;
	margin: 15px;
	margin-top: 20px;
	border: 2px solid #DBDADA;
	border-radius: 5px;
	background-color: #E7F0F7;
	font-size: 11px;
	font-weight: 600;
	padding: 15px;
	color: #708796;
}
.review-role-modal-body-sbcontainer-TextHeader{
	width: 10vw;
	font-weight: bold;
	position: relative;
}
.review-role-modal-body-sbcontainer-Text{
	padding-top: 10px;
	color: #708796;
	font-weight: 600;
}
.review-role-modal-header-sbcontainer{
	height: 6vh;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: row;
}

.review-role-modal-button{
	margin: 5px;
	border: none;
	padding: 10px;
	border-radius: 5px;
	cursor: pointer;

}