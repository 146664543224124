.csldatecontainerremove {
    font-size: 14px;
    width: 100%;
}

.react-date-picker__wrapper {
    display: flex;
    width: 190%;
    justify-content: space-between;
}

.react-date-picker__inputGroup {
    flex-grow: unset;
    display: inline-block;
}

.react-date-picker__button {
    /* display: inline-block !important; */
    float: right !important;
}
