.input-form {
    padding: 5px 29px;
}
.input-label {
    font-size: 14px;
    margin-bottom: 5px;
    font-weight: 700;
    color: rgb(27, 52, 81);
}
.input-field {
    margin-bottom: 10px;
}
.date-range {
    width: 55%;
}

.submit-btn-disable{
    margin-left: 15px;
    margin-right : 15px;
    background-color:  #888888 !important; 
    border-color: #666666 !important
}

.submit-btn-enable{
    margin-left: 15px;
    margin-right : 15px;
    background-color:  #18B8A5 !important; 
}

.no-group-companies {
    font-size: 16px;
    font-weight: 700;
}
.no-group-companies-section {
    margin-bottom: 14px;
    font-size: 14px;
    font-weight: 700;
    color: rgb(27, 52, 81);
}