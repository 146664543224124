.assign-cert-to {
    margin-bottom: -41px;
}

.assign-apply-selection {
    float: right;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: rgb(36, 179, 173) !important;
}

.assign-certmanager-gc-label {
    width: 30%;
}