.index-container {
    box-sizing: border-box;
    padding: 8px 5px 8px 15px;
}
.topbar{
    padding: 6px;
    height: 36px;
    background-color: rgb(240, 246, 252);
    margin-top:5px;
    margin-bottom:5px;
}

.add-button {
    background-color: #285b99;
    color: #ffffff;
    border: 1px solid #285b99;
    font-size: 14px;
    height: 35px;
    border-radius: 4px;
    cursor: pointer;
}

.add-button-inactive {
    background-color: #b7b7b7;
    color: #f3f0f0;
    border: 1px solid #b7b7b7;
    font-size: 14px;
    height: 35px;
    border-radius: 4px;
    cursor: none;
}

.add-button:hover{
    box-shadow: 2px 2px 3px #999;
    background-color: #213e61;
    font-weight: 600;
}

.base-title{
    border: 1px solid #D3DDE4;
    background: #E7F0F7;
    padding: 12px;
    margin: 10px;
    color: #597284;
    font-weight: 600;
    border-radius: 4px;
    font-size: 12px;
}

.table-icon-container{
    color : #1b1c1e;
    font-size: 13px;
    text-align: center;
    cursor: pointer;
    width: 100%;
    margin-top: -1px;
}

.table-ellipsis {
    cursor: pointer;
}

.table-context-menu {
    position : absolute;
    background-color: #f3f0f0;
    padding: 5px 0px;
    display: inline-block;
    width : 75px;
    right: 25px;
    box-shadow: 0px 0px 4px rgba(0,0,0,0.4);
    z-index: 1;
}

.table-header-context-menu {
    position : absolute;
    background-color: #f3f0f0;
    padding: 5px 5px;
    display: inline-block;
    width: 300px;
    right: 35px;
    box-shadow: 0px 0px 4px rgba(0,0,0,0.4);
    z-index: 10000;
    box-sizing: border-box;
}

.table-context-menu-item {
    font-size: 14px;
    padding: 2px;
    font-weight: 500;
    cursor: pointer;
    display: block;
}
.table-context-menu-item:hover{
    background-color: #e1dfdf;
    font-weight: 600;
}

.table-container{
    width: 100%;
    padding: 0px 5px 0px 5px;
    box-sizing: border-box;
}

.table-header-container{
    margin:0px 5px 0px 5px;
    padding: 10px 15px 10px 15px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    border: 1px solid #eee;
    border-bottom: none;
}

.table-header-left-pane{
    width: 60%;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

.table-header-right-pane{
    width: 40%;
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    justify-content: flex-end;
}

.table-header-role-title{
    font-size: 20px;
    font-weight: 600;
}

.dismiss-link{
    font-size: 14px;
    text-decoration: underline;
    margin-left: 25px;
    color : #0C6EE4
}

.show-na{
    font-size: 16px;
    text-decoration: underline;
    color : #0C6EE4;
    cursor: pointer;
}

.interactive-overlay-rr{
    position : absolute;
    top : 0;
    left : 0;
    z-index: 1;
    width: 100%;
    height: 100vh;
    background-color: rgba(255,255,255,0.8);
    box-sizing: border-box;
}

.multi-user-icon-container{
    position: relative;
}

.multi-user-icon-title{
    position: absolute;
    z-index: 50000;
    background-color: #fff;
    cursor: pointer;
    display: inline-block;
    padding: 5px;
    border : 1px solid #000;
    right : 7%;
}

.assignment-no-group-companies {
    margin-bottom: 14px;
    font-size: 14px;
    margin-left: 26px;
    margin-top: 14px;
    font-weight: 700;
    color: rgb(27, 52, 81);
}

.no-group-comapnies {
    font-size: 16px;
    font-weight: 700;
}