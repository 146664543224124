@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alex+Brush&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Borel&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

#root {
    font-family: 'Montserrat', sans-serif;
}

.ql-snow .ql-tooltip {
    position: initial;
}
.ant-select-multiple .ant-select-selection-item {
    background: #2F496C !important;
    border: 1px solid #2F496C !important;
    color: #ffffff !important;
    font-family: 'Montserrat', sans-serif;
    font-size: 11px;
}

.ant-select-multiple .ant-select-selection-item-remove {
    color: #ffffff !important;
}

.group-companies {
    font-size: 14px;
    font-weight: 700;
    margin: 10px 0px 10px 28px;
}

.elipse {
 max-width: 100px;
 overflow: hidden;
 text-overflow: ellipsis;
 white-space: nowrap;
}
.assessment-certtification-table{
    width : 100%;
    font-weight : 500;
    border-collapse: collapse;
}
.assessment-certtification-header{
    background-color : #F6F9FD;
    color: #0C6EE4;
    font-size : 1rem;
}
.assessment-certtification-header td{
    padding : "10px";
    border-top: 1px solid #ccc;
    border-bottom : 1px solid #ccc;
}
.assessment-certtification-data{
    font-size: 0.8rem;
}
.assessment-certtification-data td{
    padding: 10px;
    border: none;
}
.assessment-certtification-pdf{
    width : 15px;
    filter: invert(44%) sepia(78%) saturate(1761%) hue-rotate(198deg) brightness(101%) contrast(101%);
}